import React from 'react';
import * as S from './styles';

const StatsBox = ({ data }) => {
	return (
		<S.Wrapper>
			{data.content.map((stat,i) => (
				<div key={i}>
					<p>{stat.content1}</p>
					<p>{stat.content2}</p>
				</div>
			))}
		</S.Wrapper>
	);
};

export default StatsBox;
