import styled from 'styled-components';
import {
	media,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	background-color: ${setColors.lightestBlue};
	text-align: center;
	border-radius: 5px;
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}

	p:nth-of-type(1) {
		color: ${setColors.themeBlue};
		font-family: ${setFontFamily.black};
		font-size: ${setFontSize.mediumLarge};
		margin: 0;
		margin-top: 20px;
		font-weight: ${setFontWeight.extraBold};
		line-height: 1.27;
		transform: translateY(-5px);
	}
	p:nth-of-type(2) {
		font-size: ${setFontSize.mediumTiny};
		padding: 0;
		margin: 0;
		line-height: 1;
	}
	display: grid;
	gap: 20px;

	div {
		padding: 10px 10px;
		&:nth-last-child(1) {
			margin-bottom: 20px;
		}
	}

	${media.tablet`
		padding: 40px 50px;
		div {
			padding: 0 45px;
			&:nth-last-child(1) {
			margin-bottom: 0;
		}
		}
		p:nth-of-type(1) {
			font-size: ${setFontSize.normalLarge};
			margin-top: 0px;
		}
		p:nth-of-type(2) {
			font-size: ${setFontSize.veryTiny};
		}
    `};

	${media.desktop`
		display: block;
		div{
			width: 33.33%;
			box-sizing: border-box;
			float: left;
		}
		p:nth-of-type(1) {
			font-size: ${setFontSize.large50};
			margin-top: 0;
			margin-bottom: 10px;
		}
		p:nth-of-type(2) {
			font-size: ${setFontSize.mediumTiny};
			line-height: 1.5;
		}
    `};

	${media.large`
		div{
			padding: ${setFontSize.large45};
		}
		p:nth-of-type(1) {
			font-size: ${setFontSize.largest};
		}
		p:nth-of-type(2) {
			font-size: ${setFontSize.tiny};
		}
	
	`}
`;
