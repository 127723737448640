import React from 'react';
import * as S from './styles';
import { BsArrowRight } from 'react-icons/bs';
import RichTextRenderer from '../../global/RichTextRenderer';

const ClientIntro = ({ clientInfo, clientDescription }) => {
	const { employees, industry, location, title, website } = clientInfo;
	return (
		<S.Wrapper>
			<h2>{title}</h2>
			<RichTextRenderer content={clientDescription} />
			<div className="content">
				<span>
					<p>Industry</p>
					<p>{industry}</p>
				</span>
				<span>
					<p>Employees</p>
					<p>{employees}</p>
				</span>
				<span>
					<p>Location</p>
					<p>{location}</p>
				</span>
			</div>
			{website && (
				<a target="_blank" href={`http://${website}`} rel="noopener noreferrer">
					{website} <BsArrowRight style={{ paddingLeft: '0.5rem' }} />
				</a>
			)}
		</S.Wrapper>
	);
};

export default ClientIntro;
