import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../src/components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import ClientIntro from '../components/blog-category-template/client-intro/ClientIntro';
import RichTextRenderer from '../components/global/RichTextRenderer';
import WrapperThree from '../components/global/wrappers/WrapperThree';
import * as S from '../components/customer-page-template/styles';
import StatsBox from '../components/customer-page-template/stats-box/StatsBox';
import FeaturedCustomers from '../components/home-page/featured-customers/FeaturedCustomers';
import CTA from '../components/global/CTA/CTA';
import SEO from '../components/global/seo';

const CustomerPage = ({ data }) => {
	const {
		clientIntro,
		contactName,
		contactTitle,
		clientDescription,
		projectContent,
		projectResults,
		title,
		image,
		logo,
		ctaTitle,
		ctaSubtitle,
		seoDescription,
		seoJsonSchema,
	} = data.contentfulCustomers;
	return (
		<Layout>
			<SEO
				title={`Customer story with ${clientIntro.title} | WotNot`}
				description={seoDescription}
				article={true}
				image={image}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<InfoBox
					image={image}
					alt={image.title}
					contactName={contactName}
					contactTitle={contactTitle}
					title={title}
					hideImage="true"
					featuredCustomer="true"
					justifyContent="center"
					logo={logo}
					isHeroHeader
				/>
				<S.ClientWrapper>
					<ClientIntro
						clientInfo={clientIntro}
						clientDescription={clientDescription}
					/>
				</S.ClientWrapper>
			</WrapperOne>
			<S.ParentContentWrapper>
				<WrapperThree>
					<S.ContentWrapper>
						<RichTextRenderer content={projectContent} />
					</S.ContentWrapper>
				</WrapperThree>
			</S.ParentContentWrapper>
			<WrapperOne disableBottomMargin="true">
				{projectResults && (
					<S.StatsWrapper>
						<StatsBox data={projectResults} />
					</S.StatsWrapper>
				)}
				<S.CustomersWrapper>
					<FeaturedCustomers
						section10Title="More stories from WotNot customers"
						customers={data.allContentfulCustomers.nodes}
					/>
				</S.CustomersWrapper>
				<S.ctaWrapper>
					<CTA title={ctaTitle} subtitle={ctaSubtitle} />
				</S.ctaWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String, $relatedCustomerSlug: [String]) {
		contentfulCustomers(slug: { eq: $slug }) {
			contactName
			clientIntro {
				employees
				industry
				location
				title
				website
			}
			clientDescription {
				raw
			}
			contactTitle
			title
			ctaTitle
			ctaSubtitle
			seoDescription
			seoJsonSchema {
				internal {
					content
				}
			}
			projectContent {
				references {
					fluid {
						...GatsbyContentfulFluid
					}
					file {
						details {
							image {
								height
								width
							}
						}
					}
					title
				}
				raw
				references {
					... on ContentfulAsset {
						contentful_id
						__typename
						fluid {
							...GatsbyContentfulFluid
						}
					}
				}
			}
			projectResults {
				content {
					content2
					content1
				}
			}
			logo {
				file {
					url
					contentType
					details {
						image {
							height
							width
						}
					}
				}
				fixed(width: 100) {
					...GatsbyContentfulFixed
				}
				title
			}
			image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
		}
		allContentfulCustomers(filter: { slug: { in: $relatedCustomerSlug } }) {
			nodes {
				title
				slug
				cardImage {
					fluid(maxWidth: 234, maxHeight: 234) {
						...GatsbyContentfulFluid
					}
					title
				}
				cardLogo {
					fixed(width: 200) {
						...GatsbyContentfulFixed
					}
					title
				}
			}
		}
	}
`;
export default CustomerPage;
