import styled from 'styled-components';
import {
	media,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	background-color: ${setColors.gray};
	border-radius: 10px;
	padding: 26px 13px;
	h2 {
		margin: 0;
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.mediumSmall};
		font-weight: ${setFontWeight.bolder};
		line-height: 1.27;
		text-align: center;
		margin-bottom: 4px;
	}
	p {
		line-height: 1.6;
		margin: 32px 0;
		font-size: ${setFontSize.mediumTiny};
	}
	a {
		color: ${setColors.themeBlue};
		display: flex;
		align-items: center;
		margin-top: 36px;
		width: fit-content;
		text-decoration: none;
		border-bottom: 1px solid ${setColors.themeBlue};
		font-size: ${setFontSize.tiny};
	}
	.content {
		padding-top: 36px;
		display: flex;
		flex-direction: column;
	}
	span {
		padding: 10px 0;
		p {
			line-height: 1.6;
			margin: 0;
		}
		p:nth-of-type(1) {
			font-family: ${setFontFamily.bold};
			font-size: ${setFontSize.tinySmall};
			font-weight: ${setFontWeight.bolder};
			line-height: 1.27;
			margin: 0;
			margin-bottom: 4px;
		}
		font-size: ${setFontSize.normal};
	}

	${media.tablet`
		h2{
			text-align: left;
		}
		.content {
			flex-direction: row;
			justify-content: space-between;
		}
		span{
			padding: 0;
		}
		padding: 40px 50px;
		div {grid-template-columns: 1fr 1fr 1fr};
    `};

	${media.large`
		p{
			margin: 36px 0;
			font-size: ${setFontSize.tiny};
		}
		span {
			p:nth-of-type(1) {
				font-size: ${setFontSize.mediumSmall};
			}
		}
	`}
`;
