import React from 'react';
import styled from 'styled-components';
import { mediaQuery, setTextBoxWidth } from '../../../../utils/helpers';

const Wrapper = styled.div`
	display: grid;
	justify-items: center;
	width: 100%;
`;

const Content = styled.div`
	max-width: 800px;
	${mediaQuery.laptopXL`width: ${setTextBoxWidth.laptopXL};`};
	${mediaQuery.tabletL`width: ${setTextBoxWidth.tabletL};`};
	${mediaQuery.mobileXL`
		width: ${setTextBoxWidth.mobileXL} !important; 
		max-width: ${setTextBoxWidth.fullWidth} !important;
		margin: 0 auto !important;
	`};
	${mediaQuery.mobileM`width: ${setTextBoxWidth.laptopXL} !important;`};
	${mediaQuery.mobileS`width: ${setTextBoxWidth.mobileS} !important;`};
`;

const WrapperThree = ({ children }) => {
	return (
		<Wrapper>
			<Content>{children}</Content>
		</Wrapper>
	);
};

export default WrapperThree;
