import styled from 'styled-components';
import {
	media,
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../utils/helpers';

export const ctaWrapper = styled.div`
	padding-top: 55px;
	margin-bottom: 20px;
	${media.tablet`
		padding-top: 260px;
	`}
`;

export const ContentWrapper = styled.article`
	p {
		font-size: ${setFontSize.mediumTiny};
		line-height: 1.6;
		margin: 2em 0;
	}
	ul {
		margin-left: 7px;
		padding: 0;
		li {
			margin-left: 1em;
			list-style: disc outside;
			p {
				margin: 0;
			}
		}
	}
	.imgWrapper {
		padding-bottom: 10px;
		border-bottom: 1px solid ${setColors.gainsBoro};
	}
	img {
		&:hover {
			cursor: pointer;
		}
	}
	h1 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.mediumLarge48};
		line-height: 62px;
		font-weight: ${setFontWeight.extraBold};
		font-family: ${setFontFamily.black};
		text-transform: none;
		letter-spacing: -1.25px;
		margin: 25px 0;

		${mediaQuery.laptopXL`font-size: ${setFontSize.mediumLarge};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.tinyLarge};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tinyLarge};`}
	}

	h2 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.large};
		line-height: 40px;
		font-weight: ${setFontWeight.bold};
		font-family: ${setFontFamily.bold};
		text-transform: none;
		letter-spacing: -0.5px;
		margin: 25px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.small};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.mediumSmall};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.small};`}
	}

	h3 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.smaller};
		line-height: 32px;
		font-weight: ${setFontWeight.bold};
		font-family: ${setFontFamily.bold};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tinySmall};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.verySmall};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tinySmall};`}
	}

	h4 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.verySmall};
		line-height: 30px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.bold};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tiny};`}
		${mediaQuery.tabletL`font-size: ${setFontSize.mediumTiny};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tiny};`}
	}

	h5 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.veryTiny};
		line-height: 20px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.medium};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.veryTiny}`}
		${mediaQuery.tabletL`font-size: ${setFontSize.veryTiny};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.veryTiny};`}
	}

	h6 {
		color: ${setColors.lightBlack} !important;
		font-size: ${setFontSize.tiniest};
		line-height: 18px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.medium};
		text-transform: none;
		letter-spacing: 0;
		margin: 5px 0;
		${mediaQuery.laptopXL`font-size: ${setFontSize.tiniest}`}
		${mediaQuery.tabletL`font-size: ${setFontSize.tiniest};`}
        ${mediaQuery.mobileXL`font-size: ${setFontSize.tiniest};`}
	}

	strong {
		font-family: ${setFontFamily.black};
	}
	ol {
		li {
			p {
				margin: 0;
				padding: 0;
			}
			padding: 3px 0;
		}
		list-style: decimal;
		margin-left: 15px;
		margin-bottom: 20px;
		padding: 0 20px;
	}
	blockquote {
		color: ${setColors.themeBlue};
		margin: 0rem;
		p {
			::before {
				content: '\“';
			}
			font-size: ${setFontSize.tinySmall};
			line-height: 1.6;
			padding: 20px 0;
			font-style: italic;
			margin: 0;
			::after {
				content: '\”';
			}
		}
	}
	hr {
		border: 0;
		border-top: 1px solid ${setColors.gainsBoro};
		opacity: 0.5;
	}
	a {
		color: ${setColors.themeBlue};
		border-bottom: 1px solid ${setColors.themeBlue};
		text-decoration: none;
	}
	${media.tablet`
			padding: 0 60px;
		`};
	${media.large`
		p {
			font-size: ${setFontSize.tiny};
		}
		blockquote {
			p{
				font-size: ${setFontSize.mediumSmall};
			}
		}
	`}
`;

export const ClientWrapper = styled.div`
	margin-top: 10px;
	${media.tablet`
		margin-top: 50px;
	`}
`;

export const ParentContentWrapper = styled.div`
	margin-top: 20px;
	${media.tablet`
		margin-top: 50px;
	`}
`;

export const StatsWrapper = styled.div`
	margin-top: 70px;
	padding-bottom: 10px;
	${media.tablet`
		margin-top:100px;
	`}
`;

export const CustomersWrapper = styled.div`
	margin-top: 210px;
	${mediaQuery.laptopXL`margin-top: 140px;`}
	${mediaQuery.mobileXL`margin-top: 61px;`}
`;
